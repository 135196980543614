<template>
  <div class="contrastContainer" @contextmenu="preventRightClick">
    <!-- 窗体控制栏 -->
    <div class="windowCtrlContainer">
      <img class="windowCtrlImg" src="@/assets/imgs/min.png" @click="minsize" />
      <img class="windowCtrlImg" v-if="togglemaxsizeFlag == 'normal'" src="@/assets/imgs/max.png"
        @click="togglemaxsize" />
      <img class="windowCtrlImg" v-else src="@/assets/imgs/normal.png" @click="togglemaxsize" />
      <img class="windowCtrlImg" src="@/assets/imgs/close.png" @click="close" />
    </div>
    <!-- 导航栏 -->
    <div class="analysisNavContainer">
      <img src="@/assets/imgs/zuojiantou.png" class="analysisNavImg" @click="back">
      <div class="analysisNavText">直播复盘分析</div>
    </div>

    <!-- 视频分析 -->
    <div class="contrastInfoContainer" v-if="showVideo">
      <div class="analysisItemContainer" style="border-right: 1px solid #ccc;">
        <analysis-item :sentenceMarkData="sentenceMarkData.data1" :videoWidth="'28%'"></analysis-item>
      </div>
      <div class="analysisItemContainer">
        <analysis-item :sentenceMarkData="sentenceMarkData.data2" :videoWidth="'28%'"></analysis-item>
      </div>
    </div>

    <!-- 关键词/敏感词列表 -->
    <div class="wordsSummaryContainer">
      <div>关键词汇总</div>
      <div class="wordsSummaryBodyContainer">
        <div class="wordsSummaryTitleContainer" :style="wordsList && wordsList.length > 3 ? 'margin-right:4px' : ''">
          <div class="wordsSummaryTitleItem border-left border-top border-bottom">类型</div>
          <div class="wordsSummaryTitleItem border-left border-top border-bottom">词语</div>
          <div class="wordsSummaryTitleItem border-left border-top border-bottom">平台</div>
          <div class="wordsSummaryTitleItem border-left border-top border-bottom">来源</div>
          <div class="wordsSummaryTitleItem border-left border-top border-bottom">分类/等级</div>
          <div class="wordsSummaryTitleItem border-left border-top border-bottom">
            次数-视频1
          </div>
          <div class="wordsSummaryTitleItem border-left border-top border-right border-bottom">
            次数-视频2
          </div>
        </div>
        <div class="wordsSummaryContentContainer">
          <div v-for="(item, index) in wordsList" :key="index" class="wordsSummaryContentItemContainer">
            <div class="wordsSummaryContentItem border-left border-bottom">{{ item.wordsTypeStr }}
            </div>
            <div class="wordsSummaryContentItem border-left border-bottom">{{ item.name }}</div>
            <div class="wordsSummaryContentItem border-left border-bottom">{{ item.platformTypeStr
              }}</div>
            <div class="wordsSummaryContentItem border-left border-bottom">{{ item.resourceTypeStr
              }}</div>
            <div class="wordsSummaryContentItem border-left border-bottom"> {{ item.wordsType === 0
              ? item.levelStr : item.typeStr }}</div>
            <div class="wordsSummaryContentItem border-left border-bottom">{{
              item.countNum1 }}
            </div>
            <div class="wordsSummaryContentItem border-left border-right border-bottom">{{
              item.countNum2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myUtils from '../../../../utils/utils';
import analysisItem from "../../../commonComponent/analysis-contrast-item.vue"
export default {
  components: {
    analysisItem
  },
  data() {
    return {
      togglemaxsizeFlag: 'normal',
      // 关键词/敏感词列表
      wordsList: [],
      showVideo: false,
      sentenceMarkData: {},
    };
  },

  created() {
    // 禁止用户按F5和F12
    window.addEventListener("keydown", (event) => {
      if (event.key === 'F12' || event.key === 'F5') {
        event.preventDefault()
      }
    });
  },

  mounted() {
    this.getContrastInfo(this.$route.query.contrastId)
  },

  methods: {
    // 禁止鼠标右键
    preventRightClick(event) {
      event.preventDefault();
    },
    // 获取对比信息
    getContrastInfo(contrastId) {
      this.sentenceMarkData.data1 = {};
      this.sentenceMarkData.data2 = {};

      this.$httpClient.video.lockanalysiscontrast({ contrastId }).then(res => {
        if (res.code == 0 && res.data) {

          this.sentenceMarkData.data1.sentenceMarkList = [];
          this.sentenceMarkData.data2.sentenceMarkList = [];

          // 设置数据1的段落词语信息
          if (res.data.SentenceMark1.videoInfo && res.data.SentenceMark1.audioaAlyses) {
            // 录制的视频
            res.data.SentenceMark1.audioaAlyses.forEach(item => {
              if (item.Status == 0) {
                this.sentenceMarkData.data1.sentenceMarkList.push(JSON.parse(item.DataJson))
              }
            });
          } else if (res.data.SentenceMark1.uploadFile && res.data.SentenceMark1.fileAudioaAlyses) {
            // 上传的文件
            res.data.SentenceMark1.fileAudioaAlyses.forEach(item => {
              if (item.status == 0) {
                this.sentenceMarkData.data1.sentenceMarkList.push(JSON.parse(item.dataJson))
              }
            });
          }

          if (this.sentenceMarkData.data1.sentenceMarkList.length > 0) {
            this.sentenceMarkData.data1.sentenceMarkList.sort((a, b) => a.currentSort - b.currentSort);
            // 设置段落的开始时间和结束时间
            this.sentenceMarkData.data1.sentenceMarkList.forEach((item, index) => {
              item.markContent = item.content;
              if (item.items && item.items.length > 0) {
                if (index == 0) {
                  item.startTime = 0;
                } else {
                  item.startTime = item.items[0].startTime;
                }
                item.endTime = item.items[item.items.length - 1].endTime;
              }
            })
          }

          // 设置数据2的段落词语信息
          if (res.data.SentenceMark2.videoInfo && res.data.SentenceMark2.audioaAlyses) {
            // 录制的视频
            res.data.SentenceMark2.audioaAlyses.forEach(item => {
              if (item.Status == 0) {
                this.sentenceMarkData.data2.sentenceMarkList.push(JSON.parse(item.DataJson))
              }
            });
          } else if (res.data.SentenceMark2.uploadFile && res.data.SentenceMark2.fileAudioaAlyses) {
            // 上传的文件
            res.data.SentenceMark2.fileAudioaAlyses.forEach(item => {
              if (item.status == 0) {
                this.sentenceMarkData.data2.sentenceMarkList.push(JSON.parse(item.dataJson))
              }
            });
          }

          if (this.sentenceMarkData.data2.sentenceMarkList.length > 0) {
            this.sentenceMarkData.data2.sentenceMarkList.sort((a, b) => a.currentSort - b.currentSort);
            // 设置段落的开始时间和结束时间
            this.sentenceMarkData.data2.sentenceMarkList.forEach((item, index) => {
              item.markContent = item.content;
              if (item.items && item.items.length > 0) {
                if (index == 0) {
                  item.startTime = 0;
                } else {
                  item.startTime = item.items[0].startTime;
                }
                item.endTime = item.items[item.items.length - 1].endTime;
              }
            })
          }

          // 视频播放地址
          this.sentenceMarkData.data1.playUrl = res.data.SentenceMark1.playUrl;
          this.sentenceMarkData.data2.playUrl = res.data.SentenceMark2.playUrl;

          // 视频信息
          if (res.data.SentenceMark1.videoInfo) {
            this.sentenceMarkData.data1.videoInfo = res.data.SentenceMark1.videoInfo;
            if (this.sentenceMarkData.data1.videoInfo.Duration) {
              this.sentenceMarkData.data1.videoInfo.Duration = myUtils.toformatTime(this.sentenceMarkData.data1.videoInfo.Duration * 1000);
            }
          }
          if (res.data.SentenceMark2.videoInfo) {
            this.sentenceMarkData.data2.videoInfo = res.data.SentenceMark2.videoInfo;
            if (this.sentenceMarkData.data2.videoInfo.Duration) {
              this.sentenceMarkData.data2.videoInfo.Duration = myUtils.toformatTime(this.sentenceMarkData.data2.videoInfo.Duration * 1000);
            }
          }

          // 主播信息
          this.sentenceMarkData.data1.anchorInfo = res.data.SentenceMark1.anchorInfo;
          this.sentenceMarkData.data2.anchorInfo = res.data.SentenceMark2.anchorInfo;

          // 文件信息
          if (res.data.SentenceMark1.uploadFile) {
            this.sentenceMarkData.data1.fileInfo = res.data.SentenceMark1.uploadFile;
            if (this.sentenceMarkData.data1.fileInfo.fileDuration) {
              this.sentenceMarkData.data1.fileInfo.fileDuration = myUtils.toformatTime(this.sentenceMarkData.data1.fileInfo.fileDuration * 1000);
            }
          }
          if (res.data.SentenceMark2.uploadFile) {
            this.sentenceMarkData.data2.fileInfo = res.data.SentenceMark2.uploadFile;
            if (this.sentenceMarkData.data2.fileInfo.fileDuration) {
              this.sentenceMarkData.data2.fileInfo.fileDuration = myUtils.toformatTime(this.sentenceMarkData.data2.fileInfo.fileDuration * 1000);
            }
          }

          this.showVideo = true;

          this.countWords();
        }
      })
    },
    // 整理敏感词/关键词列表
    countWords() {
      this.wordsList = [];

      if (this.sentenceMarkData.data1.sentenceMarkList && this.sentenceMarkData.data1.sentenceMarkList.length > 0) {
        // 将段落1的关键词/敏感词添加到wordsList
        this.sentenceMarkData.data1.sentenceMarkList.forEach(sentence => {
          if (sentence.wordsList) {
            sentence.wordsList.forEach(sentenceWords => {
              let exist = false;
              this.wordsList.forEach(words => {
                // 如果已存在，则增加数量
                if (words.name === sentenceWords.name && words.wordsType === sentenceWords.wordsType) {
                  words.countNum1 += sentenceWords.countNum;
                  exist = true;
                }
              })
              if (!exist) {
                sentenceWords.countNum1 = sentenceWords.countNum;
                this.wordsList.push(sentenceWords);
              }

            });
          }
        });

        if (this.sentenceMarkData.data2.sentenceMarkList && this.sentenceMarkData.data2.sentenceMarkList.length > 0) {
          // 遍历段落2，进行比对
          this.sentenceMarkData.data2.sentenceMarkList.forEach(sentence => {
            if (sentence.wordsList) {
              sentence.wordsList.forEach(sentenceWords => {
                let exist = false;
                this.wordsList.forEach(words => {
                  if (words.name === sentenceWords.name && words.wordsType === sentenceWords.wordsType) {
                    // 如果已经存在，增加数量
                    words.countNum2 = words.countNum2 ? words.countNum2 + sentenceWords.countNum : sentenceWords.countNum;
                    exist = true;
                  }
                });
                if (!exist) {
                  sentenceWords.countNum2 = sentenceWords.countNum;
                  this.wordsList.push(sentenceWords);
                }

              })
            }

          })


        }


      }
      //  else if (this.sentenceMarkList2.wordsList && this.sentenceMarkList2.wordsList.length > 0) {
      //   // 将段落2的关键词/敏感词添加到wordsList
      //   this.sentenceMarkList2.forEach(sentence => {
      //     if (sentence.wordsList) {
      //       sentence.wordsList.forEach(words => {
      //         words.countNum2 = words.countNum;
      //         this.wordsList.push(words);
      //       });
      //     }
      //   });

      // }

      if (this.wordsList.length > 0) {
        this.wordsList.sort((a, b) => b.countNum1 - a.countNum1);
      }

    },
    // 最大化/恢复正常
    togglemaxsize() {
      this.$httpClient.form.togglemaxsize().then((res) => {
        if (res.code == 0) {
          if (this.togglemaxsizeFlag == 'normal') {
            this.togglemaxsizeFlag = 'max';
          } else {
            this.togglemaxsizeFlag = 'normal';
          }
        }
      })
    },
    // 最小化
    minsize() {
      this.$httpClient.form.minsize().then((res) => {
        console.log("最小化")
      })
    },
    // 关闭
    close() {
      this.$httpClient.form.close().then((res) => {
        console.log("关闭")
      })
    },
    // 返回上一页
    back() {
      this.$store.commit("saveReplayPageMenu", { page: "tabs", menu: "compare" });
      this.$store.commit("saveHomeMenu", 2);
      this.$router.back();
    }
  },
};
</script>
<style scoped>
.border-right {
  border-right: 0.5px solid #ccc;
}

.border-left {
  border-left: 0.5px solid #ccc;
}

.border-bottom {
  border-bottom: 0.5px solid #ccc;
}

.border-top {
  border-top: 0.5px solid #ccc;
}

.wordsSummaryTitleItem {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5F7F9;
  font-size: 13px;
  font-weight: 400;
  color: #2E3742;
  height: 28px;
}

.wordsSummaryContentItem {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #2E3742;
  height: 28px;
}

.wordsSummaryContentItemContainer {
  display: flex;
  align-items: center;
}

.wordsSummaryContentContainer::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.wordsSummaryContentContainer::-webkit-scrollbar {
  width: 4px;
}

.wordsSummaryContentContainer {
  height: 90px;
  overflow-y: auto;
}

.wordsSummaryTitleContainer {
  display: flex;
  align-items: center;

}

.wordsSummaryBodyContainer {
  margin-top: 10px;
}

.wordsSummaryContainer {
  margin-top: 14px;
  font-weight: 600;
  font-size: 14px;
  color: #2E3742;

}

.analysisItemContainer {
  flex: 1;
  padding: 10px;
  height: calc(100vh - 265px);
}

.contrastInfoContainer {
  display: flex;
}

.analysisNavText {
  font-weight: 500;
  font-size: 16px;
  color: #2E3742;
  margin-left: 10px;
}

.analysisNavImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.analysisNavContainer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DCE0E7;
  padding-bottom: 10px;
}

.windowCtrlImg {
  width: 16px;
  height: 16px;
  margin-left: 21px;
  cursor: pointer;
}

.windowCtrlContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contrastContainer {
  padding: 23px 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
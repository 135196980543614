<template>
    <div style="display: flex; flex-direction: column;height: 100%;">
        <!-- 视频信息 -->
        <div class="analysisFileContainer" v-if="videoInfo && videoInfo.Id">
            <div class="analysisFileLeftContainer">
                <img :src="anchorInfo.AnchorAvatar" class="analysisCompereImg">
                <div class="analysisCompereName">{{ anchorInfo.AnchorName }}</div>
            </div>
            <div class="analysisFileRightContainer">
                <div class="analysisFileRightItemContainer rightBorder">
                    <div>录制开始时间</div>
                    <div style="margin-top: 4px;">{{ videoInfo.StartTime.substring(0, 16) }}</div>
                </div>
                <div class="analysisFileRightItemContainer rightBorder">
                    <div>录制结束时间</div>
                    <div style="margin-top: 4px;">{{ videoInfo.EndTime.substring(0, 16) }}</div>
                </div>
                <div class="analysisFileRightItemContainer">
                    <div>录制时长</div>
                    <div style="margin-top: 4px;">{{ durationStr }}</div>
                </div>
            </div>
        </div>
        <!-- 上传的文件信息 -->
        <div class="analysisFileContainer" v-else>
            <div class="analysisFileLeftContainer">
                <img src="@/assets/imgs/video.png" class="analysisCompereImg">
                <div class="analysisCompereName">{{ fileInfo.fileName }}</div>
            </div>
            <div class="analysisFileRightContainer">
                <div class="analysisFileRightItemContainer rightBorder">
                    <div>上传时间</div>
                    <div style="margin-top: 4px;">{{ fileInfo.uploadTime.substring(0, 16) }}</div>
                </div>
                <div class="analysisFileRightItemContainer rightBorder">
                    <div>分析时间</div>
                    <div style="margin-top: 4px;">{{ fileInfo.analysisTime.substring(0, 16) }}</div>
                </div>
            </div>
        </div>


        <!-- 敏感词/关键词/语速汇总 -->
        <div class="wordsContainer">
            <div class="wordsItemContainer">
                <div class="wordsItemColorContainer">
                    <div class="wordsItemColor" style="background: rgba(255, 58, 25, 0.2);"></div>
                    <div class="wordsItemText">敏感词：{{ wordsInfo.sensitiveWordsNum }}次</div>
                </div>
                <div class="indiciaBtn" v-if="!wordsInfo.markSensitive" @click="markClick('sensitive', true)">点击标注
                </div>
                <div class="cancelIndiciaBtn" v-else @click="markClick('sensitive', false)">取消标注</div>
            </div>
            <div class="wordsItemContainer">
                <div class="wordsItemColorContainer">
                    <div class="wordsItemColor" style="background: rgba(0,183,255,0.2);"></div>
                    <div class="wordsItemText">关键词：{{ wordsInfo.cruxWordsNum }}次</div>
                </div>
                <div class="indiciaBtn" v-if="!wordsInfo.markCrux" @click="markClick('crux', true)">点击标注</div>
                <div class="cancelIndiciaBtn" v-else @click="markClick('crux', false)">取消标注</div>
            </div>
            <div class="wordsItemContainer" v-if="sentenceMarkData.playUrl">
                <div class="wordsItemColorContainer">
                    <div class="wordsItemText" v-if="!analysisChar">语速：**字/分钟</div>
                    <div class="wordsItemText" v-else>语速：{{ parseInt(charCountNum / (videoDuration / 60)) }}字/分钟</div>
                </div>
                <div class="indiciaBtn" @click="analysisChar = true" v-if="!analysisChar">点击分析</div>
                <div class="indiciaBtn" @click="analysisChar = false" v-if="analysisChar">隐藏分析</div>
            </div>
        </div>
        <!-- 视频/音频分析 -->
        <div class="videoAnalysisContainer">
            <!-- 视频/音频 -->
            <div class="videoContainer" v-if="sentenceMarkData.playUrl" :style="'width: ' + videoWidth">
                <video-player ref="videoPlayer" class="video" :options="playerOptions" :playsinline="true"
                    @timeupdate="onPlayerTimeupdate" @canplay="canplay"></video-player>
            </div>
            <!-- 文字识别 -->
            <div class="discernContainer">

                <div class="discernSearchContainer">
                    <!-- 搜索内容标注关键字 -->
                    <div style="display: flex; align-items: center;" v-if="searchWordIndexInfo.name">
                        <div style="font-size: 14px; color: #444;margin-right: 10px;">数量：{{ searchWordIndexInfo.count }}
                        </div>
                        <div v-if="searchWordIndexInfo.count > 0" style="display: flex; align-items: center;">
                            <i class="el-icon-caret-left" @click="prevSearchWords"
                                style="font-size: 26px; color: dodgerblue; cursor: pointer;"></i>
                            <i class="el-icon-caret-right" @click="nextSearchWords"
                                style="font-size: 26px; color: dodgerblue; cursor: pointer;"></i>
                        </div>
                    </div>
                    <el-input size="mini" placeholder="输入内容标注关键字" suffix-icon="el-icon-search"
                        v-model="searchWordIndexInfo.name" style="width: 200px;" @input="markKeywords" clearable>
                    </el-input>
                    <!-- 导出文字内容 -->
                    <el-button @click="exportTxt" style="margin-left: 10px;" size="mini" type="primary">导出文字</el-button>
                </div>
                <!-- 音频/视频文件的文字段落 -->
                <div class="wordsBodyContainer" v-if="sentenceMarkData.playUrl">
                    <div v-for="item in sentenceMarkList" class="wordsBodyItemContainer" ref="dom">
                        <img :src="anchorInfo.AnchorAvatar" class="wordsBodyAvatar"
                            v-if="anchorInfo && anchorInfo.AnchorAvatar">
                        <img src="@/assets/imgs/avatar.png" class="wordsBodyAvatar" v-else>
                        <div class="wordsBodyContentContainer">
                            <div style="display: flex; align-items: center;">
                                <div class="wordsBodyContentText1">说话人</div>
                                <div class="wordsBodyContentText2">{{ toformatTime(item.items[0].startTime) }}</div>
                                <div class="wordsBodyContentText2" v-if="analysisChar"> {{ " 语速：" +
                                    parseInt(item.charNumSecond)
                                    }}字/分钟</div>
                            </div>
                            <!-- 段落 -->
                            <div class="paragraphContainer">
                                <!-- 让两个元素重叠 -->
                                <div style="position: relative; z-index: -1;"
                                    v-html="'<span>' + item.searchMarkContent + '</span>'">
                                </div>
                                <div style="position: absolute; left: 0; top: 0; z-index: -2;"
                                    v-html="'<span>' + item.markContent + '</span>'">
                                </div>
                                <div style="position: absolute; left: 0; top: 0;">
                                    <span
                                        :class="(wordsItem.startTime < videoCurrentTime && videoCurrentTime < wordsItem.endTime)
                                            && (wordsItem.word != '，' && wordsItem.word != '。' && wordsItem.word != '！' && wordsItem.word != '？' && wordsItem.word != '、') ? 'wordTimeHover' : 'word'"
                                        v-for="(wordsItem, index) in item.items" :key="index"
                                        @click="playerReadied(wordsItem.startTime)">{{ wordsItem.word }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 文本文件的文字段落 -->
                <div class="wordsBodyContainer" v-else>
                    <div v-for="item in sentenceMarkList" class="wordsBodyItemContainer" ref="dom">
                        <img :src="anchorInfo.AnchorAvatar" class="wordsBodyAvatar"
                            v-if="anchorInfo && anchorInfo.AnchorAvatar">
                        <img src="@/assets/imgs/avatar.png" class="wordsBodyAvatar" v-else>
                        <div class="wordsBodyContentContainer">
                            <div class="wordsBodyContentText1">说话人</div>
                            <!-- 段落 -->
                            <!-- <div style="position: relative; z-index: -1;white-space: pre-wrap;margin-top: 10px;"
                                v-html="'<span>' + item.markContent + '</span>'">
                            </div> -->
                            <div class="paragraphContainer">
                                <div style="position: relative; z-index: 1;white-space: pre-wrap;margin-top: 10px;"
                                    v-html="'<span>' + item.searchMarkContent + '</span>'">
                                </div>
                                <div style="position: absolute; left: 0; top: 0; z-index: -1;white-space: pre-wrap;margin-top: 10px;"
                                    v-html="'<span>' + item.markContent + '</span>'">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import myUtils from '../../utils/utils';
export default {
    props: {
        // 数据
        sentenceMarkData: {
            type: Object,
            required: true
        },
        // 视频播放器宽度，如：28%
        videoWidth: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            videoCurrentTime: 0, // 播放器当前进度，毫秒
            // 播放器参数
            playerOptions: {
                // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                autoplay: false, // 如果true,浏览器准备好时开始播放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: true, // 循环播放
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        src: this.sentenceMarkData.playUrl // url地址
                    }
                ],
                hls: true,
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: false, // 当前时间和持续时间的分隔符
                    durationDisplay: false,  // 显示持续时间
                    remainingTimeDisplay: true, // // 是否显示剩余时间功能
                    fullscreenToggle: true, // 全屏按钮
                }
            },
            // 关键词/敏感词数据
            wordsInfo: {
                wordsList: [], // 关键词/敏感词列表
                cruxWordsNum: 0, // 关键词个数
                sensitiveWordsNum: 0, // 敏感词个数
                markCrux: true, // 是否标注关键词
                markSensitive: true, // 是否标注敏感词
            },
            sentenceMarkList: this.sentenceMarkData.sentenceMarkList,
            videoInfo: this.sentenceMarkData.videoInfo,
            anchorInfo: this.sentenceMarkData.anchorInfo,
            fileInfo: this.sentenceMarkData.fileInfo,
            durationStr: "",
            currentParagraphIndex: 0, // 当前段落
            // 搜索标注关键字
            searchWordIndexInfo: {
                name: "",
                wordIndex: 0,
                paragraphIndex: 0,
                count: 0,
            },
            videoDuration: "", // 视频时长
            charCountNum: "", // 文字总数量
            analysisChar: false,
        };
    },

    mounted() {
        this.countWords();

        // 计算时长
        if (this.videoInfo && this.videoInfo.Duration) {
            this.durationStr = "";
            let arr = this.videoInfo.Duration.split(":");
            if (arr[0] && parseInt(arr[0])) {
                this.durationStr += arr[0] + "时";
            }
            if (arr[1] && parseInt(arr[1])) {
                this.durationStr += arr[1] + "分";
            }
        }
    },

    methods: {
        // 视频加载完成回调事件
        canplay() {
            this.videoDuration = this.$refs.videoPlayer.player.duration();
            this.charCountNum = 0;
            if (this.sentenceMarkList && this.sentenceMarkList.length > 0) {
                this.sentenceMarkList.forEach(item => {
                    let tempContent = item.content.replaceAll("，", "").replaceAll("。", "").replaceAll("？", "").replaceAll("、", "");
                    this.charCountNum += tempContent.length;
                    item.charNumSecond = tempContent.length / ((item.endTime - item.startTime) / 1000) * 60;
                })
            }
        },
        // 跳转到下一个搜索词
        nextSearchWords() {
            if (this.searchWordIndexInfo.wordIndex >= this.searchWordIndexInfo.count - 1) {
                this.searchWordIndexInfo.wordIndex = 0;
            } else {
                this.searchWordIndexInfo.wordIndex++;
            }
            let oldParagraphIndex = this.searchWordIndexInfo.paragraphIndex;
            this.mark(true);
            this.$nextTick(() => {
                if (oldParagraphIndex != this.searchWordIndexInfo.paragraphIndex) {
                    this.$refs.dom[this.searchWordIndexInfo.paragraphIndex].scrollIntoView({ behavior: "smooth" });
                    this.$refs.videoPlayer.player.pause();
                }

            });
        },
        // 跳转到上一个搜索词
        prevSearchWords() {
            if (this.searchWordIndexInfo.wordIndex <= 0) {
                this.searchWordIndexInfo.wordIndex = this.searchWordIndexInfo.count - 1;
            } else {
                this.searchWordIndexInfo.wordIndex--;
            }
            let oldParagraphIndex = this.searchWordIndexInfo.paragraphIndex;
            this.mark(true);
            this.$nextTick(() => {
                if (oldParagraphIndex != this.searchWordIndexInfo.paragraphIndex) {
                    this.$refs.dom[this.searchWordIndexInfo.paragraphIndex].scrollIntoView({ behavior: "smooth" });
                    this.$refs.videoPlayer.player.pause();
                }
            });
        },
        // 搜索关键字
        markKeywords() {
            this.mark();
            this.$nextTick(() => {
                if (this.searchWordIndexInfo.name) {
                    let index = -1;
                    for (let i = 0; i < this.sentenceMarkList.length; i++) {
                        let item = this.sentenceMarkList[i];
                        if (item.content.indexOf(this.searchWordIndexInfo.name) != -1) {
                            index = i;
                            break;
                        }
                    }
                    if (index != -1) {
                        this.$refs.dom[index].scrollIntoView({ behavior: "smooth" });
                        this.$refs.videoPlayer.player.pause();
                    }
                }
            })
        },
        // 导出文字内容
        exportTxt() {
            let requestData = {
                type: this.videoInfo && this.videoInfo.VideoId ? 0 : 1,
                id: this.videoInfo && this.videoInfo.VideoId ? this.videoInfo.VideoId : this.fileInfo.fileId,
                fileName: encodeURIComponent(this.videoInfo && this.videoInfo.VideoId ? this.videoInfo.VideoName : this.fileInfo.fileName),
            }
            this.$httpClient.export.alysestxt(requestData).then(res => {
                if (res.code == 0 && res.data) {
                    this.$message.success("导出成功");
                }
            });
        },
        // 标注/取消标注按钮回调
        markClick(type, flag) {
            if (type == "sensitive") {
                // 敏感词
                this.wordsInfo.markSensitive = flag;
            } else if (type == "crux") {
                // 关键词
                this.wordsInfo.markCrux = flag;
            }
            this.$emit("countWords", JSON.parse(JSON.stringify(this.wordsInfo)));
            this.mark();
        },
        // 标注段落的关键词
        mark(isSearch) {
            this.searchWordIndexInfo.count = 0;
            if (this.sentenceMarkList) {
                let wordIndex = 0;
                this.sentenceMarkList.forEach((sentenceItem, index) => {
                    sentenceItem.markContent = sentenceItem.content;
                    sentenceItem.searchMarkContent = sentenceItem.content;

                    if (this.wordsInfo.wordsList.length > 0) {

                        // 标注敏感词
                        if (this.wordsInfo.markSensitive) {
                            this.wordsInfo.wordsList.forEach(wordItem => {
                                if (wordItem.wordsType == 0) {
                                    if (sentenceItem.content.indexOf(wordItem.name) != -1) {
                                        sentenceItem.markContent = sentenceItem.markContent.replace(new RegExp(wordItem.name, 'g'), "<span style='background: pink'>" + wordItem.name + "</span>");
                                    }
                                }
                            })
                        }
                        // 标注关键词
                        if (this.wordsInfo.markCrux) {
                            this.wordsInfo.wordsList.forEach(wordItem => {
                                if (wordItem.wordsType == 1) {
                                    if (sentenceItem.content.indexOf(wordItem.name) != -1) {
                                        sentenceItem.markContent = sentenceItem.markContent.replace(new RegExp(wordItem.name, 'g'), "<span style='background: paleturquoise'>" + wordItem.name + "</span>");
                                    }
                                }
                            })
                        }

                    }

                    // 标注搜索词
                    if (this.searchWordIndexInfo.name) {
                        sentenceItem.searchMarkContent = sentenceItem.searchMarkContent.replace(new RegExp(this.searchWordIndexInfo.name, 'g'), "<span style='background: darkorange'>" + this.searchWordIndexInfo.name + "</span>");
                        // 累计搜索词的数量
                        const regex = new RegExp(this.searchWordIndexInfo.name, 'g');
                        let count = sentenceItem.searchMarkContent.match(regex) ? sentenceItem.searchMarkContent.match(regex).length : 0;
                        this.searchWordIndexInfo.count += count;
                    }

                    if (isSearch) {
                        // 标注上一个/下一个搜索词
                        const regex = new RegExp(this.searchWordIndexInfo.name, 'g');
                        let count = sentenceItem.searchMarkContent.match(regex) ? sentenceItem.searchMarkContent.match(regex).length : 0;

                        for (let i = 0; i < count; i++) {
                            if (wordIndex == this.searchWordIndexInfo.wordIndex) {
                                let text = sentenceItem.searchMarkContent;
                                // 找到第i个词的索引
                                let searchStartIndex = 0;
                                for (let j = 0; j <= i; j++) {
                                    searchStartIndex = text.indexOf(this.searchWordIndexInfo.name, searchStartIndex == 0 ? 0 : searchStartIndex + 1);
                                }

                                // 拼接标注颜色
                                sentenceItem.searchMarkContent = text.substring(0, searchStartIndex);
                                sentenceItem.searchMarkContent += "<span style='background:indianred'>" + this.searchWordIndexInfo.name + "</span>";
                                sentenceItem.searchMarkContent += text.substring(searchStartIndex + this.searchWordIndexInfo.name.length);

                                // 要跳转的段落索引
                                this.searchWordIndexInfo.paragraphIndex = index;
                            }
                            wordIndex++;
                        }
                    }
                })
            }

        },
        // 整理关键词、敏感词数据
        countWords() {
            this.wordsInfo.wordsList = [];
            this.wordsInfo.cruxWordsNum = 0;
            this.wordsInfo.sensitiveWordsNum = 0;

            if (this.sentenceMarkList && this.sentenceMarkList.length > 0) {
                this.sentenceMarkList.forEach(item => {

                    let wordsList = item.wordsList;
                    if (wordsList && wordsList.length > 0) {

                        wordsList.forEach(wordsItem => {
                            if (this.wordsInfo.wordsList.length < 1) {
                                this.wordsInfo.wordsList.push(wordsItem);
                            } else {
                                // 判断数组里面是否已经存在当前关键词/敏感词，如果是，则只增加出现数量
                                let exist = false;
                                this.wordsInfo.wordsList.forEach(haveItem => {
                                    if (haveItem.name == wordsItem.name && haveItem.wordsType == wordsItem.wordsType) {
                                        exist = true;
                                        haveItem.countNum += wordsItem.countNum;
                                    }
                                });
                                if (!exist) {
                                    this.wordsInfo.wordsList.push(wordsItem);
                                }
                            }
                        })
                    }
                });

            }

            if (this.wordsInfo.wordsList.length > 0) {
                this.wordsInfo.wordsList.forEach(wordsItem => {
                    // 统计数量
                    if (wordsItem.wordsType == 0) {
                        // 敏感词
                        this.wordsInfo.sensitiveWordsNum += wordsItem.countNum;
                    } else if (wordsItem.wordsType == 1) {
                        // 关键词
                        this.wordsInfo.cruxWordsNum += wordsItem.countNum;
                    }
                })
            }

            // 排序
            if (this.wordsInfo.wordsList) {
                this.wordsInfo.wordsList.sort((a, b) => b.countNum - a.countNum);
            }

            this.mark();

            this.$emit("countWords", JSON.parse(JSON.stringify(this.wordsInfo)));
        },
        // 设置播放器进度，秒
        playerReadied(second) {
            this.$refs.videoPlayer.player.currentTime(second / 1000);
            this.$refs.videoPlayer.player.play();
        },
        // 播放器进度回调
        onPlayerTimeupdate(player) {
            this.videoCurrentTime = player.cache_.currentTime * 1000;

            // 滚动滚动条
            if (this.sentenceMarkList && this.sentenceMarkList.length > 0) {
                // 获取当前正在播放的段落索引
                let currentIndex = 0;

                let setFlag = false;
                for (let index = 0; index < this.sentenceMarkList.length; index++) {

                    let item = this.sentenceMarkList[index];
                    let nextItem = index + 1 == this.sentenceMarkList.length ? null : this.sentenceMarkList[index + 1];

                    if (nextItem) {
                        if (item.startTime < this.videoCurrentTime && this.videoCurrentTime < nextItem.startTime) {
                            currentIndex = index;
                            setFlag = true;
                        }
                    }

                }

                if (!setFlag) {
                    currentIndex = this.sentenceMarkList.length - 1;
                }

                this.sentenceMarkList.forEach((item, index) => {
                    if (item.startTime < this.videoCurrentTime && this.videoCurrentTime < item.endTime) {
                        currentIndex = index;
                    }
                });

                // 滚动滚动条到指定的段落元素
                // currentIndex -= 1;
                if (this.currentParagraphIndex != currentIndex) {
                    if (currentIndex < 0) {
                        this.$refs.dom[0].scrollIntoView({ behavior: "smooth" });
                    } else {
                        this.$refs.dom[currentIndex].scrollIntoView({ behavior: "smooth" });
                    }
                    this.currentParagraphIndex = currentIndex;
                }
            }
        },
        // 毫秒时间戳转成时分秒格式
        toformatTime(val) {
            return myUtils.toformatTime(val);
        },
    },
};
</script>
<style scoped>
.discernSearchContainer {
    padding-bottom: 10px;
    display: flex;
    justify-content: end;
}

.wordTimeHover {
    cursor: pointer;
    background: rgb(51, 109, 244);
    border-radius: 4px;
    color: #FFF;
    z-index: 99;
}

.word {
    cursor: pointer;
    background: transparent;
    border-radius: 0px;
    color: #2E3742;
}

.paragraphContainer {
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
    margin-top: 6px;
    position: relative;
    line-height: 19px;
    letter-spacing: 2px;
}

.wordsBodyContentText2 {
    font-weight: 400;
    font-size: 12px;
    color: #95A1AF;
    margin-top: 8px;
    margin-left: 4px;
}

.wordsBodyContentText1 {
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
    margin-top: 8px;
}

.wordsBodyContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;
    flex-grow: 1;
}

.wordsBodyAvatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 0.5px #ccc solid;
}

.wordsBodyItemContainer {
    margin-bottom: 12px;
    display: flex;
    word-wrap: break-word;
    word-break: break-all;
}

.wordsBodyContainer::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.wordsBodyContainer::-webkit-scrollbar {
    width: 4px;
}

.wordsBodyContainer {
    height: calc(100vh - 250px - 158px - 38px);
    overflow-y: auto;
    box-sizing: border-box;
}

.discernContainer {
    margin-left: 12px;
    box-sizing: border-box;
    flex-grow: 1;
    width: 0;
}

/deep/ .vjs-play-control {
    display: none;
}

/deep/ .video-js .vjs-big-play-button {
    top: calc(50% - 22px);
    left: calc(50% - 42px);
}

.video {
    width: calc(100% - 0.5px);
}

.videoContainer {
    background-color: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.videoAnalysisContainer {
    display: flex;
    margin-top: 16px;
    flex-grow: 1;
    min-height: calc(100% - 191px);
}

.cancelIndiciaBtn {
    width: 72px;
    height: 22px;
    border-radius: 4px;
    border: 1px solid #B4BCCA;
    font-size: 13px;
    color: #2E3742;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    cursor: pointer;
}

.indiciaBtn {
    width: 72px;
    height: 22px;
    border-radius: 4px;
    border: 1px solid #0077FF;
    font-size: 13px;
    color: #0077FF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    cursor: pointer;
}

.wordsItemText {
    font-weight: 500;
    font-size: 13px;
    color: #2E3742;
    margin-left: 4px;
}

.wordsItemColor {
    width: 24px;
    height: 16px;
    border-radius: 4px;
}

.wordsItemColorContainer {
    display: flex;
    align-items: center;
}

.wordsItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.wordsContainer {
    height: 68px;
    background: #F5F7F9;
    border-radius: 4px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
}

.analysisFileRightItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
}

.analysisFileRightContainer {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
}

.analysisCompereName {
    color: #2E3742;
    font-size: 14px;
    margin-left: 10px;
    max-width: 280px;
}

.analysisCompereImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0.5px #ccc solid;
}

.analysisFileLeftContainer {
    display: flex;
    align-items: center;
}

.analysisFileContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}
</style>